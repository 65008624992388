<template>
  <div class="resetPassword-page container-fluid">
    <div class="container">
      <div class="reset-box">
        <span class="smart-forex">Smart Forex </span>
        <span class="reset-password">Reset Password</span>
      </div>
      <div class="reset-form account-form">
        <form @submit.prevent="submit">
          <div class="form-group col-md-12 col-sm-12 email">
            <input
              v-model="form.password"
              type="password"
              placeholder="New password*"
              required
            />
          </div>
          <div class="form-group col-md-12 col-sm-12 email">
            <input
              v-model="form.password_repeat"
              type="password"
              placeholder="New password repeat*"
              required
            />
          </div>
          <div class="form-group col-md-12 col-sm-12 button-reset">
            <button type="submit">Save</button>
          </div>
          <div class="form-group col-md-12 col-sm-12 enter">
            <span>Enter</span>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      form: {
        password: '',
        password_repeat: '',
      },
    }
  },

  methods: {
    submit() {
      this.resetPassword({
        password: this.form.password,
        code: this.$route.params.code,
      })
        .then(() => {
          this.$router.push({ name: 'account' })
        })
        .catch(err => {
          console.warn(err)
        })
    },

    ...mapActions('User', ['resetPassword']),
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
